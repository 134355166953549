import { Layout } from 'antd';
import styled from '@emotion/styled';

const { Content } = Layout;

export const WebLayoutContent = styled(Content)(() => ({
  display: 'flex',
  padding: '2.4rem',
  flexDirection: 'column',
  minHeight: 'calc(100vh - 64px)',
}));
