import React from 'react';
import { Flex } from 'antd';
import { usePathname } from 'next/navigation';
import { Title } from '@shared/ui/typography';
import { AppRoutes } from '@shared/constants/appRoutes';

const titles: Record<AppRoutes.dashboard | AppRoutes.templates, string> = {
  [AppRoutes.dashboard]: 'Media Kits',
  [AppRoutes.templates]: 'Templates',
};

const useTitle = () => {
  const pathname = usePathname();

  return titles[pathname as AppRoutes.dashboard | AppRoutes.templates] || '';
};

export const HeaderLeftSide = () => {
  const title = useTitle();

  return (
    <Flex align="center">
      <Title
        level={4}
        titleProps={{
          '@media (max-width: 768px)': {
            display: 'none',
          },
        }}
      >
        {title}
      </Title>
    </Flex>
  );
};
