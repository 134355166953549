'use client';

import React from 'react';
import SocialAccounts from '@/lib/components/web/SocialAccounts';
import { useSelector, selectRegistrationStep } from '@shared/redux';

export const SocialAccountsProvider = (props: React.PropsWithChildren) => {
  const registrationStep = useSelector(selectRegistrationStep);

  return <>{registrationStep === 2 ? <SocialAccounts /> : props.children}</>;
};
