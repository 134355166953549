import React from 'react';
import { Flex } from 'antd';
import { Upgrade } from '@shared/ui/upgrade';
import { HeaderLeftSide } from './header-left-side';
import { ProfileMenu } from '@shared/ui/profile-menu';
import { SubHeaderContainer } from './sub-header-container';

export const SubHeader = () => {
  return (
    <SubHeaderContainer>
      <HeaderLeftSide />
      <Flex gap={8} align="center">
        <Upgrade />
        <ProfileMenu />
      </Flex>
    </SubHeaderContainer>
  );
};
