'use client';

import React from 'react';
import { Form } from 'antd';
import { Box } from '@shared/ui/box';
import { Steps } from '@shared/ui/Utils';
import { Forms } from '@/lib/types/forms';
import { Text, Title } from '@shared/ui/typography';
import { Controller, useForm } from 'react-hook-form';
import { sendGTMEvent } from '@next/third-parties/google';
import { selectAuthUser, useSelector } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { FormMessage } from '@/lib/components/auth/FormMessage';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { FormContainer } from '@/lib/components/auth/FormWrapper';
import { useStoreSocialAccountsMutation } from '@/lib/services/auth';
import SubmitButton from '@/lib/components/web/SocialAccounts/components/SubmitButton';
import { SocialInput } from '@/lib/components/web/SocialAccounts/components/SocialInput';

function SocialAccounts() {
  const posthog = useLocalPostHog();
  const authUser = useSelector(selectAuthUser);
  const [submit] = useStoreSocialAccountsMutation();
  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<Forms.SocialAccounts>();

  const rootMessage = errors?.root?.message;

  const onSubmit = async (formValues: Forms.SocialAccounts) => {
    const res: any = await submit(formValues);

    if (res?.error) {
      return setError('root', {
        type: 'server',
        message: res?.error?.message,
      });
    }

    sendGTMEvent({
      event: 'sign_up',
      email: authUser?.email,
    });

    try {
      const properties: Record<string, any> = {};

      if (formValues.instagram) {
        properties['instagram'] = formValues.instagram;
      }
      if (formValues.tiktok) {
        properties['tiktok'] = formValues.tiktok;
      }
      if (formValues.youtube) {
        properties['youtube'] = formValues.youtube;
      }

      posthog?.capture(PosthogEvents.RegisterStep3, properties);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  return (
    <Box
      boxStyles={{
        gap: '4rem',
        display: 'flex',
        maxWidth: '40rem',
        marginTop: '4rem',
        marginInline: 'auto',
        paddingTop: '2.8rem',
        flexDirection: 'column',
      }}
    >
      <Steps current={2} />
      <Box
        boxStyles={{
          gap: '.5rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title level={2}>Add your social media accounts</Title>
        <Text type="secondary">
          Please make sure your username is entered correctly.
        </Text>
      </Box>
      <FormContainer collapsed={!!rootMessage}>
        <FormMessage
          type="danger"
          textAlign="center"
          collapsed={!!rootMessage}
          message={rootMessage || ''}
        />
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Form size="large" component="div" layout="vertical">
            <Controller
              name="instagram"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.Item
                  hasFeedback={!!error?.message || !!field.value}
                  validateStatus={
                    !!error?.message
                      ? 'error'
                      : !!field.value
                      ? 'success'
                      : undefined
                  }
                >
                  <SocialInput
                    {...field}
                    setError={setError}
                    platform="instagram"
                    clearErrors={clearErrors}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="tiktok"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.Item
                  hasFeedback={!!error?.message || !!field.value}
                  validateStatus={
                    !!error?.message
                      ? 'error'
                      : !!field.value
                      ? 'success'
                      : undefined
                  }
                >
                  <SocialInput
                    {...field}
                    platform="tiktok"
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="youtube"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.Item
                  hasFeedback={!!error?.message || !!field.value}
                  validateStatus={
                    !!error?.message
                      ? 'error'
                      : !!field.value
                      ? 'success'
                      : undefined
                  }
                >
                  <SocialInput
                    {...field}
                    platform="youtube"
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </Form.Item>
              )}
            />
            <Form.Item>
              <SubmitButton control={control} />
            </Form.Item>
          </Form>
        </form>
      </FormContainer>
    </Box>
  );
}

export default SocialAccounts;
