import React from 'react';
import { Button } from 'antd';
import { Forms } from '@/lib/types/forms';
import { Control, useFormState, useWatch } from 'react-hook-form';

function SubmitButton({ control }: { control: Control<Forms.SocialAccounts> }) {
  const [tiktok, youtube, instagram] = useWatch({
    control,
    name: ['tiktok', 'youtube', 'instagram'],
  });

  const { isSubmitting } = useFormState({
    control,
  });

  const disabled = !tiktok?.trim() && !youtube?.trim() && !instagram?.trim();

  return (
    <Button
      block
      type="primary"
      htmlType="submit"
      disabled={disabled}
      loading={isSubmitting}
    >
      Create My MediaKit
    </Button>
  );
}

export default SubmitButton;
