import React from 'react';
import { selectAuthUser, useSelector } from '@shared/redux';

export const useTidio = () => {
  const user = useSelector(selectAuthUser);
  const [tidioChatApiIsOpen, toggleTidioChatApi] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      (document as any).tidioIdentify = {
        name: user.name,
        email: user.email,
        distinct_id: user.email,
      };
    }
  }, [user]);

  React.useEffect(() => {
    const tidioChatScript = document.getElementById('tidio-chat-script');
    if (tidioChatScript) return;

    const tidioScript = document.createElement('script');
    tidioScript.src = '//code.tidio.co/yivtlm6iqjf4znvq5wfrx9spgucpyvhp.js';
    tidioScript.id = 'tidio-chat-script';

    document.body.appendChild(tidioScript);

    const onTidioChatApiReady = () => {
      window.tidioChatApi.hide();
    };

    const onTidioChatApiClose = () => {
      toggleTidioChatApi(false);

      window.tidioChatApi.hide();
    };

    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady);
      window.tidioChatApi.on('close', onTidioChatApiClose);
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady);
      document.addEventListener('tidioChat-close', onTidioChatApiClose);
    }
  }, []);

  return {
    tidioChatApiIsOpen,
    toggleTidioChatApi,
  };
};
