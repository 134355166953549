import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SecurityUserSvg = () => (
  <svg
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.99343 1.48654L3.66677 2.73321C2.9001 3.01987 2.27344 3.92653 2.27344 4.74653V9.69986C2.27344 10.4865 2.79344 11.5199 3.42678 11.9932L6.29344 14.1332C7.23344 14.8399 8.78009 14.8399 9.72009 14.1332L12.5868 11.9932C13.2201 11.5199 13.7401 10.4865 13.7401 9.69986V4.74653C13.7401 3.92653 13.1134 3.01987 12.3468 2.73321L9.0201 1.48654C8.45343 1.27988 7.54676 1.27988 6.99343 1.48654Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.99849 7.28027C7.97183 7.28027 7.93849 7.28027 7.91182 7.28027C7.28516 7.26027 6.78516 6.74027 6.78516 6.10693C6.78516 5.46027 7.31183 4.93359 7.9585 4.93359C8.60516 4.93359 9.13184 5.46027 9.13184 6.10693C9.12517 6.74693 8.62516 7.26027 7.99849 7.28027Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.67336 9.14723C6.03336 9.57389 6.03336 10.2739 6.67336 10.7006C7.40003 11.1872 8.59336 11.1872 9.32003 10.7006C9.96003 10.2739 9.96003 9.57389 9.32003 9.14723C8.60003 8.66056 7.40669 8.66056 6.67336 9.14723Z"
    />
  </svg>
);

export const SecurityUserIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SecurityUserSvg} {...props} />
);
