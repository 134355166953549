'use client';

import React from 'react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Forms } from '@/lib/types/forms';
import { useSearchParams } from 'next/navigation';
import StoreUsername from '@shared/ui/StoreUsername';
import { selectAuthUser, useSelector } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import {
  useCheckUsernameMutation,
  useStoreUsernameMutation,
} from '@/lib/services/auth';

const Container = styled('div')(() => ({
  gap: '4rem',
  display: 'flex',
  maxWidth: '40rem',
  marginTop: '4rem',
  minHeight: '100vh',
  alignItems: 'center',
  marginInline: 'auto',
  paddingTop: '2.8rem',
  flexDirection: 'column',
}));

export const HandleProvider = (props: React.PropsWithChildren) => {
  const posthog = useLocalPostHog();
  const searchParams = useSearchParams();
  const [submit] = useStoreUsernameMutation();
  const authUser = useSelector(selectAuthUser);
  const [checkUsername, response] = useCheckUsernameMutation();

  const { control, setError, handleSubmit, clearErrors } =
    useForm<Forms.Register.StoreUsername>();

  const usernameError = searchParams.get('error');

  React.useEffect(() => {
    if (usernameError) {
      setError('username', { message: usernameError });
    }
  }, [setError, usernameError]);

  const onSubmit = async (formValues: Forms.Register.StoreUsername) => {
    const res: any = await submit(formValues);

    if (res?.error) {
      return setError('username', {
        message: res?.error?.message,
      });
    }

    try {
      posthog?.capture(PosthogEvents.RegisterStep1, {
        handle: formValues.username,
      });
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  if (!authUser) {
    return null;
  }

  if (authUser?.handle) {
    return props.children;
  }

  return (
    <Container>
      <StoreUsername
        current={1}
        control={control}
        response={response}
        setError={setError}
        submit={checkUsername}
        clearErrors={clearErrors}
        hasError={!!usernameError}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Container>
  );
};
