import React from 'react';
import Cookies from 'universal-cookie';
import { useMeMutation } from '@/lib/services/auth';
import { AppRoutes } from '@shared/constants/appRoutes';
import { usePathname, useRouter } from 'next/navigation';
import { selectAccessToken, useSelector } from '@shared/redux';

const cookies = new Cookies();

export const useWebLayout = () => {
  const [me] = useMeMutation();
  const pathname = usePathname();
  const { replace } = useRouter();
  const accessToken = useSelector(selectAccessToken);
  const [loading, setLoading] = React.useState(true);
  const [opacity, setOpacity] = React.useState<number>(0);

  React.useEffect(() => {
    setTimeout(() => setOpacity(1), 400);
  }, []);

  React.useEffect(() => {
    async function start() {
      try {
        await me().unwrap();

        if (pathname === AppRoutes.index) {
          replace(AppRoutes.base);
        }
      } catch (e) {
        cookies.remove('access_token');

        window.location.replace(AppRoutes.login);
      }

      setLoading(false);
    }

    if (accessToken) {
      start().then().catch(console.error);
    } else {
      window.location.replace(AppRoutes.login);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, replace, accessToken]);

  return { loading, opacity };
};
