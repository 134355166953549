import { Menu } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

export const StyledMenu = styled(Menu)(() => ({
  [`&.${prefixCls}-menu.${prefixCls}-menu-root`]: {
    borderRight: 'none',
    backgroundColor: 'transparent',
  },
}));
