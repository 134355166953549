import React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { BaseType } from 'antd/es/typography/Base';
import { fastAnimation, prefixCls } from '@shared/theme';

const { Text } = Typography;

const FormMessageWrapper = styled('div')(
  ({
    collapsed,
    textAlign,
  }: {
    collapsed?: boolean;
    textAlign?: 'center' | 'right' | 'left';
  }) => ({
    textAlign,
    display: 'grid',
    gridTemplateRows: collapsed ? '1fr' : '0fr',
    transition: `grid-template-rows ${fastAnimation} ease-out`,
  }),
);

const FormMessageText = styled(Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    overflow: 'hidden',
    fontSize: '1.6rem',
  },
}));

export function FormMessage({
  type,
  message,
  collapsed,
  textAlign,
}: {
  type?: BaseType;
  collapsed?: boolean;
  message: React.ReactNode;
  textAlign?: 'center' | 'right' | 'left';
}) {
  return (
    <FormMessageWrapper textAlign={textAlign} collapsed={collapsed}>
      <FormMessageText type={type}>{message}</FormMessageText>
    </FormMessageWrapper>
  );
}
