'use client';

import React from 'react';
import PageSpin from '@/lib/components/web/PageSpin';
import { useWebLayout } from '@app/(web)/useWebLayout';
import { SubHeader } from '@features/web-layout/ui/sub-header';
import { HandleProvider } from '@/lib/providers/handleProvider';
import { MainLayout } from '@features/web-layout/ui/main-layout';
import { StyledHeader } from '@features/web-layout/ui/styled-header';
import { ContentLayout } from '@features/web-layout/ui/content-layout';
import { WebLayoutSider } from '@features/web-layout/ui/web-layout-sider';
import { WebLayoutContent } from '@features/web-layout/ui/web-layout-content';
import { SocialAccountsProvider } from '@/lib/providers/socialAccountsProvider';
import { PosthogIdentifyProvider } from '@/lib/providers/posthogIdentifyProvider';
import { WebLayoutSiderTopMenu } from '@features/web-layout/ui/web-layout-sider-top-menu';
import { WebLayoutSiderBottomMenu } from '@features/web-layout/ui/web-layout-sider-bottom-menu';

export default function WebLayout({ children }: { children: React.ReactNode }) {
  const { opacity, loading } = useWebLayout();

  return loading ? (
    <PageSpin />
  ) : (
    <HandleProvider>
      <SocialAccountsProvider>
        <PosthogIdentifyProvider>
          <MainLayout hasSider opacity={opacity}>
            <WebLayoutSider>
              <WebLayoutSiderTopMenu />
              <WebLayoutSiderBottomMenu />
            </WebLayoutSider>
            <ContentLayout>
              <StyledHeader>
                <SubHeader />
              </StyledHeader>
              <WebLayoutContent>{children}</WebLayoutContent>
            </ContentLayout>
          </MainLayout>
        </PosthogIdentifyProvider>
      </SocialAccountsProvider>
    </HandleProvider>
  );
}
