import { api } from './api';
import { urlHelper } from '@/lib/utils/urlHelper';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { SocialAccounts } from '@/lib/types/socialAccounts';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

export const socialAccounts = api.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.mutation<
      SocialAccounts.Search.ResultItem[],
      SocialAccounts.Search.Params
    >({
      transformErrorResponse,
      query: ({ q, platform }) => ({
        method: 'GET',
        url: urlHelper(ApiEndpoints.socialAccounts, { q, platform }),
      }),
    }),
  }),
});

export const { useSearchMutation } = socialAccounts;
