import { Layout } from 'antd';
import { prefixCls } from '@shared/theme';
import styled, { CSSObject } from '@emotion/styled';

export const MainLayout = styled(Layout, {
  shouldForwardProp: (prop) => prop !== 'opacity',
})(({ opacity }: { opacity: CSSObject['opacity'] }) => ({
  [`&.${prefixCls}-layout`]: {
    opacity,
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'row',
    transition: 'opacity .4s',
  },
}));
