import React from 'react';
import { Divider, MenuProps } from 'antd';
import { StyledMenu } from './styled-menu';
import { MessageOutlined } from '@ant-design/icons';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { useTidio } from '@/lib/hooks/useTidio';
import { onSiderCollapse, useDispatch } from '@shared/redux';
import { SecurityUserIcon } from '@shared/custom-icons/security-user';

export const WebLayoutSiderBottomMenu: React.FC = () => {
  const dispatch = useDispatch();
  const b768 = useBreakpoint(768);
  const { tidioChatApiIsOpen, toggleTidioChatApi } = useTidio();

  const bottomMenuItems: MenuProps['items'] = [
    {
      key: 'contact-us',
      label: 'Contact us',
      icon: <MessageOutlined />,
      onClick: () => {
        if (b768) {
          dispatch(onSiderCollapse(true));
        }

        if (window.tidioChatApi) {
          if (tidioChatApiIsOpen) {
            window.tidioChatApi.close();
            window.tidioChatApi.hide();
            toggleTidioChatApi(false);
          } else {
            window.tidioChatApi.show();
            window.tidioChatApi.open();
            toggleTidioChatApi(true);
          }
        }
      },
    },
    {
      key: 'privacy-policy',
      icon: <SecurityUserIcon />,
      label: (
        <a href="https://viralmango.com/privacy-policy/" target="_blank">
          Privacy policy
        </a>
      ),
      onClick: b768 ? () => dispatch(onSiderCollapse(true)) : undefined,
    },
  ];

  return (
    <div>
      <Divider />
      <StyledMenu
        mode="inline"
        selectedKeys={[]}
        items={bottomMenuItems}
        defaultSelectedKeys={[]}
      />
    </div>
  );
};
