import styled from '@emotion/styled';
import { fastAnimation } from '@shared/theme';

export const FormContainer = styled('div')(
  ({ collapsed }: { collapsed?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: collapsed ? '2.4rem' : 0,
    transition: `gap ${fastAnimation} ease-out`,
  }),
);
