import React from 'react';
import Link from 'next/link';
import { StyledMenu } from './styled-menu';
import { usePathname } from 'next/navigation';
import { AppRoutes } from '@shared/constants/appRoutes';
import { onSiderCollapse, useDispatch } from '@shared/redux';
import { HomeOutlined, ThunderboltOutlined } from '@ant-design/icons';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

export const WebLayoutSiderTopMenu: React.FC = () => {
  const path = usePathname();
  const dispatch = useDispatch();
  const b768 = useBreakpoint(768);
  const posthog = useLocalPostHog();

  const topMenuItems = [
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: <Link href={AppRoutes.dashboard}>Media Kits</Link>,
      onClick: b768 ? () => dispatch(onSiderCollapse(true)) : undefined,
    },
    {
      key: 'templates',
      icon: <ThunderboltOutlined />,
      label: <Link href={AppRoutes.templates}>Templates</Link>,
      onClick: b768
        ? () => {
            dispatch(onSiderCollapse(true));

            try {
              posthog?.capture(PosthogEvents.TemplatesClick);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          }
        : () => {
            try {
              posthog?.capture(PosthogEvents.TemplatesClick);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
    },
  ];

  return (
    <StyledMenu
      mode="inline"
      items={topMenuItems}
      selectedKeys={[path.replace('/', '')]}
    />
  );
};
