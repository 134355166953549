import React from 'react';
import { Flex, Layout } from 'antd';
import styled from '@emotion/styled';
import { breakpoints, prefixCls } from '@shared/theme';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import {
  onSiderCollapse,
  selectSiderCollapsed,
  useDispatch,
  useSelector,
} from '@shared/redux';

const { Sider } = Layout;

export const openedSiderWidth = 262;

export const collapsedSiderWidth = 0;

export const StyledSider = styled(Sider, {
  shouldForwardProp: (propName) => propName !== 'aa',
})(({ collapsed }: { collapsed?: boolean }) => ({
  [`& > .${prefixCls}-layout-sider-children`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderRight: '1px solid #f0f0f0',
    '& > .mediakit-flex': {
      padding: '2.4rem .8rem 1.6rem .8rem',
    },
  },
  [`&.${prefixCls}-layout-sider.${prefixCls}-layout-sider-light`]: {
    top: 0,
    left: 0,
    zIndex: 1000,
    height: '100%',
    display: 'flex',
    position: 'fixed',
    background: '#fff',
    insetBlockStart: 0,
    insetInlineStart: 0,
    justifyContent: 'space-between',
    width: !collapsed ? openedSiderWidth : collapsedSiderWidth,
    minWidth: !collapsed ? openedSiderWidth : collapsedSiderWidth,
    maxWidth: !collapsed ? openedSiderWidth : collapsedSiderWidth,
    flex: `0 0 ${!collapsed ? openedSiderWidth : collapsedSiderWidth}px`,
    [`& .${prefixCls}-layout-sider-zero-width-trigger`]: {
      display: 'none',
      zIndex: 1600,
      [`@media (max-width: ${breakpoints.md})`]: {
        display: 'flex',
      },
    },
  },
}));

export const StyledDiv = styled('div', {
  shouldForwardProp: (propName) => propName !== 'collapsed',
})(({ collapsed }: { collapsed?: boolean }) => ({
  display: 'flex',
  overflow: 'hidden',
  transition: 'all 0.2s ease 0s',
  width: !collapsed ? openedSiderWidth : collapsedSiderWidth,
  minWidth: !collapsed ? openedSiderWidth : collapsedSiderWidth,
  maxWidth: !collapsed ? openedSiderWidth : collapsedSiderWidth,
  flex: `0 0 ${!collapsed ? openedSiderWidth : collapsedSiderWidth}px`,
  [`@media (max-width: ${breakpoints.md})`]: {
    display: 'none',
    width: collapsedSiderWidth,
    minWidth: collapsedSiderWidth,
    maxWidth: collapsedSiderWidth,
    flex: `0 0 ${collapsedSiderWidth}px`,
  },
}));

export const StyledMask = styled('div', {
  shouldForwardProp: (propName) => propName !== 'collapsed',
})(({ collapsed }: { collapsed?: boolean }) => ({
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
  height: '100%',
  display: 'none',
  position: 'fixed',
  background: 'rgba(0, 0, 0, 0.5)',
  [`@media (max-width: ${breakpoints.md})`]: {
    display: collapsed ? 'none' : 'block',
  },
}));

export const Mask = ({
  onClick,
  collapsed,
}: {
  collapsed: boolean;
  onClick: () => void;
}) => {
  return <StyledMask collapsed={collapsed} onClick={onClick} />;
};

export const WebLayoutSider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const b768 = useBreakpoint(768);
  const collapsed = useSelector(selectSiderCollapsed);

  React.useEffect(() => {
    if (typeof window === undefined) return;

    if (b768) {
      dispatch(onSiderCollapse(true));
    } else {
      dispatch(onSiderCollapse(false));
    }
  }, [b768, dispatch]);

  return (
    <>
      <StyledDiv collapsed={collapsed} />
      <Mask
        collapsed={collapsed}
        onClick={() => dispatch(onSiderCollapse(true))}
      />
      <StyledSider
        collapsible
        theme="light"
        breakpoint="md"
        collapsed={collapsed}
        width={openedSiderWidth}
        collapsedWidth={collapsedSiderWidth}
        zeroWidthTriggerStyle={{ zIndex: 1600, top: 10 }}
        onCollapse={(collapsed) => dispatch(onSiderCollapse(collapsed))}
      >
        <Flex style={{ width: '100%' }} vertical justify="space-between">
          {children}
        </Flex>
      </StyledSider>
    </>
  );
};
