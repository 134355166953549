import { Layout } from 'antd';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';

const { Header } = Layout;

export const StyledHeader = styled(Header)(() => ({
  [`&.${prefixCls}-layout-header`]: {
    top: 0,
    zIndex: 999,
    position: 'sticky',
    paddingInline: '2.4rem',
    width: '100%',
  },
}));
