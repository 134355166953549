import React from 'react';
import Image from 'next/image';
import { Box } from '@shared/ui/box';
import debounce from 'lodash.debounce';
import { prefixCls } from '@shared/theme';
import tiktok from '@shared/icons/tiktok.svg';
import youtube from '@shared/icons/youtube.svg';
import instagram from '@shared/icons/instagram.svg';
import { useSearchMutation } from '@/lib/services/socialAccounts';
// import { SocialSelect } from '@/@shared/ui/web/SocialAccounts/components/Select';
// import { NotFoundContent } from '@/@shared/ui/web/SocialAccounts/components/NotFoundContent';
// import { AutoCompleteLabel } from '@/@shared/ui/web/SocialAccounts/components/AutoCompleteLabel';
import { Input } from 'antd';

const icons: any = {
  tiktok: {
    src: tiktok,
    alt: 'tiktok',
  },
  youtube: {
    src: youtube,
    alt: 'youtube',
  },
  instagram: {
    src: instagram,
    alt: 'instagram',
  },
};

export const SocialInput = React.forwardRef(
  ({ platform, setError, clearErrors, ...restProps }: any, ref) => {
    const [search, res] = useSearchMutation();

    React.useEffect(() => {
      if (res.isSuccess && !res.data?.length) {
        setError(platform, { message: ' ' });
      }
    }, [res.data, platform, setError, res.isSuccess]);

    const debouncedOnSearch = debounce((value: string) => {
      if (!value?.trim()) {
        return;
      }

      clearErrors(platform);
      search({ q: value, platform });
    }, 300);

    const onDropdownVisibleChange = (open: boolean) => {
      if (!open) {
        clearErrors(platform);

        res.reset();
      }
    };

    return (
      <Box
        boxStyles={{
          gap: '.8rem',
          display: 'flex',
        }}
      >
        <Image src={icons[platform].src} alt={icons[platform].alt} />
        <Box
          boxStyles={{
            position: 'relative',
            alignItems: 'center',
            [`& .${prefixCls}-select-arrow .${prefixCls}-down.${prefixCls}-select-suffix`]:
              {
                display: 'none',
              },
          }}
        >
          <Input
            prefix={
              <Box
                as="span"
                className={`${prefixCls}-input-prefix`}
                boxStyles={{
                  zIndex: 1,
                  fontSize: 16,
                  width: 'max-content',
                  alignItems: 'center',
                }}
              >
                @
              </Box>
            }
            placeholder="username"
            {...restProps}
          />
          {/*<Box*/}
          {/*  as="span"*/}
          {/*  className={`${prefixCls}-input-prefix`}*/}
          {/*  boxStyles={{*/}
          {/*    left: 12,*/}
          {/*    zIndex: 1,*/}
          {/*    fontSize: 16,*/}
          {/*    lineHeight: 2,*/}
          {/*    width: 'max-content',*/}
          {/*    position: 'absolute',*/}
          {/*    alignItems: 'center',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  @*/}
          {/*</Box>*/}
          {/*<SocialSelect*/}
          {/*  {...restProps}*/}
          {/*  showSearch*/}
          {/*  ref={ref as any}*/}
          {/*  filterOption={false}*/}
          {/*  autoClearSearchValue*/}
          {/*  placeholder="username"*/}
          {/*  optionLabelProp="label"*/}
          {/*  loading={res.isLoading}*/}
          {/*  onSearch={debouncedOnSearch}*/}
          {/*  onDropdownVisibleChange={onDropdownVisibleChange}*/}
          {/*  notFoundContent={res.isSuccess ? <NotFoundContent /> : null}*/}
          {/*>*/}
          {/*  {res?.data?.map((item) => (*/}
          {/*    <AntdSelect.Option*/}
          {/*      key={item.username}*/}
          {/*      value={item.username}*/}
          {/*      label={item.username}*/}
          {/*    >*/}
          {/*      <AutoCompleteLabel {...item} />*/}
          {/*    </AntdSelect.Option>*/}
          {/*  ))}*/}
          {/*</SocialSelect>*/}
        </Box>
      </Box>
    );
  },
);

SocialInput.displayName = 'SocialInput';
